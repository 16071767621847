import React from "react";
import ReactDOM from "react-dom";
import ServiceWorkerMessage from "./components/ServiceWorkerMessage";

export default {
    onUpdate: (registration) => {
        const waitingServiceWorker = registration.waiting;

        if (waitingServiceWorker) {
            ReactDOM.render(
                <ServiceWorkerMessage
                    message="A new version is available"
                    worker={waitingServiceWorker}
                />,
                document.getElementById("worker-message")
            );
        }
    },
    onSuccess: () => {
        ReactDOM.render(
            <ServiceWorkerMessage message="Content is cached for offline use" />,
            document.getElementById("worker-message")
        );
    }
};

import styled from "styled-components";

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
    padding: 10px;
    text-align: center;
    width: 125px;
    height: 125px;
    border: 1px solid ${({ theme }) => theme.borderColor};
    border-radius: 8px;
    transition-duration: 0.3s;
    transition-property: box-shadow, transform;
    box-shadow: 0 4px 8px 0 ${({ theme }) => theme.boxShadow};

    &:hover {
        transform: translate3D(0, -1px, 0) scale(1);
        box-shadow: 0 8px 16px 0 ${({ theme }) => theme.boxShadow};
    }

    .card {
        &__title {
            font-size: 16px;
            font-weight: 500;
            height: 45px;
        }

        &__buttons {
            display: flex;
            justify-content: center;
            width: 100%;

            &__play {
                padding: 5px;
                margin-left: 24px;
            }

            &__stop {
                visibility: ${({ isPlaying }) =>
                    isPlaying ? "visible" : "hidden"};
                transition: 0.1s;
                font-size: 20px;
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }

    @media (min-width: 500px) {
        width: 250px;
        height: auto;

        .card {
            &__title {
                font-size: 20px;
                height: auto;
            }

            &__buttons {
                &__play {
                    padding-left: 10px;
                    padding-right: 10px;
                    margin-left: 20px;
                }
            }
        }
    }
`;

CardContainer.displayName = "CardContainer";

export default CardContainer;

const white = "#ffffff";
const black = "#000000";
const gray = "#cccccc";
const blue = "#46b1c9";

export const lightTheme = {
    backgroundColor: white,
    textColor: black,
    buttonColor: blue,
    buttonTextColor: white,
    borderColor: gray,
    boxShadow: "rgba(0, 0, 0, 0.2)"
};

export const darkTheme = {
    backgroundColor: black,
    textColor: white,
    buttonColor: blue,
    buttonTextColor: white,
    borderColor: gray,
    boxShadow: "rgba(255, 255, 255, 0.2)"
};

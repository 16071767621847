import React from "react";
import PropTypes from "prop-types";
import CardContainer from "./Styles";
import Button from "../Button";

const Card = ({
    title,
    className = "",
    buttonText = "Play Sound",
    onClick,
    soundKey,
    activeSoundKey,
    resetActiveSoundKey
}) => {
    const isPlaying = soundKey === activeSoundKey;
    const audio = document.querySelector(`audio[data-key="${soundKey}"]`);
    if (audio) audio.onended = resetActiveSoundKey;

    const stopSoundHandler = () => {
        if (isPlaying) {
            resetActiveSoundKey();
            audio.pause();
        }
    };

    const keyDownHandler = (e) => {
        const enter = e.keyCode === 13;

        if (enter) {
            stopSoundHandler();
        }
    };

    return (
        <CardContainer className={className} isPlaying={isPlaying}>
            <h2 className="card__title">{title}</h2>
            <div className="card__buttons">
                <Button className="card__buttons__play" onClick={onClick}>
                    {buttonText}
                </Button>
                <span
                    tabIndex={isPlaying ? "0" : "-1"}
                    className="card__buttons__stop"
                    onClick={stopSoundHandler}
                    onKeyDown={keyDownHandler}
                    role="button"
                >
                    &times;
                </span>
            </div>
        </CardContainer>
    );
};

Card.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    buttonText: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    soundKey: PropTypes.number.isRequired,
    activeSoundKey: PropTypes.number.isRequired,
    resetActiveSoundKey: PropTypes.func.isRequired
};

export default Card;

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    html {
        background: ${({ theme }) => theme.backgroundColor};
        transition: background-color 0.3s;
        -webkit-tap-highlight-color: transparent;

        @media(display-mode: standalone) {
            user-select: none;
        }
    }

    body {
        margin: 0 0 30px 0;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
            "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
            "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: ${({ theme }) => theme.textColor};
    }
`;

export default GlobalStyle;

import styled from "styled-components";

const ServiceWorkerMessageContainer = styled.div`
    font-size: 14px;
    visibility: visible;
    max-width: 250px;
    margin: 0 auto;
    background-color: #333333;
    color: #ffffff;
    text-align: center;
    border-radius: 8px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 40px;
    animation: fadein 0.5s, fadeout 0.5s 4.75s;

    .message {
        &__text {
            display: inline;
            margin-right: 10px;
        }

        &__button {
            border: 1px solid #46b1c9;
            background: #46b1c9;
            color: #ffffff;
            padding: 3px 5px;

            &:hover {
                background: #46b1c9;
                color: #ffffff;
            }
        }
    }

    @keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }
        to {
            bottom: 40px;
            opacity: 1;
        }
    }

    @keyframes fadeout {
        from {
            bottom: 40px;
            opacity: 1;
        }
        to {
            bottom: 0;
            opacity: 0;
        }
    }
`;

ServiceWorkerMessageContainer.displayName = "ServiceWorkerMessageContainer";

export default ServiceWorkerMessageContainer;

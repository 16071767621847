import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import ServiceWorkerMessageContainer from "./Styles";

const ServiceWorkerMessage = ({ message, worker }) => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        if (worker) {
            worker.addEventListener("statechange", (event) => {
                if (event.target.state === "activated") {
                    window.location.reload();
                }
            });
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 5000);
    }, []);

    return (
        <>
            {show && (
                <ServiceWorkerMessageContainer role="alert">
                    <p className="message__text">{message}</p>
                    {worker && (
                        <Button
                            className="message__button"
                            onClick={() => {
                                setTimeout(() => {
                                    setShow(false);
                                }, 300);
                                worker.postMessage({
                                    type: "SKIP_WAITING"
                                });
                            }}
                        >
                            Update
                        </Button>
                    )}
                </ServiceWorkerMessageContainer>
            )}
        </>
    );
};

ServiceWorkerMessage.propTypes = {
    message: PropTypes.string.isRequired,
    worker: PropTypes.object // eslint-disable-line
};

export default ServiceWorkerMessage;

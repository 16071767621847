import styled from "styled-components";

const ButtonContainer = styled.button`
    border: 1px solid ${({ theme }) => theme.buttonColor};
    border-radius: 5px;
    margin: 0;
    padding: 5px 10px;
    width: auto;
    overflow: visible;
    background: ${({ theme }) => theme.buttonColor};
    color: ${({ theme }) => theme.buttonTextColor};
    font: inherit;
    font-size: 12px;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.backgroundColor};
        color: ${({ theme }) => theme.buttonColor};
    }
`;

ButtonContainer.displayName = "ButtonContainer";

export default ButtonContainer;

import React from "react";
import sound1 from "../../assets/frolic.mp3";
import sound2 from "../../assets/price-is-right-horn.mp3";
import sound3 from "../../assets/sad-trombone.mp3";
import sound4 from "../../assets/oh-brother.mp3";
import sound5 from "../../assets/circus.mp3";
import sound6 from "../../assets/what-peter-griffin.mp3";
import sound7 from "../../assets/bad-titanic.mp3";
import sound8 from "../../assets/oh-no-no.mp3";
import sound9 from "../../assets/ah-haha.mp3";
import sound10 from "../../assets/anime-wow.mp3";
import sound11 from "../../assets/at-this-moment.mp3";
import sound12 from "../../assets/badum-tsss.mp3";
import sound13 from "../../assets/coffin-dance.mp3";
import sound14 from "../../assets/coronavirus.mp3";
import sound15 from "../../assets/corona-time.mp3";
import sound16 from "../../assets/daequan.mp3";
import sound17 from "../../assets/fortnite-death.mp3";
import sound18 from "../../assets/ha-fish.mp3";
import sound19 from "../../assets/i-didnt-ask.mp3";
import sound20 from "../../assets/mexican-laugh.mp3";
import sound21 from "../../assets/okay.mp3";
import sound22 from "../../assets/omg.mp3";
import sound23 from "../../assets/sad.mp3";
import sound24 from "../../assets/spongebob-fail.mp3";
import sound25 from "../../assets/terrible-intro.mp3";
import sound26 from "../../assets/terrorist.mp3";
import sound27 from "../../assets/we-got-him.mp3";
import sound28 from "../../assets/worlds-smallest-violin.mp3";
import sound29 from "../../assets/lamar.mp3";
import sound30 from "../../assets/i-hate-it-here.mp3";
import sound31 from "../../assets/no-this-is-patrick.mp3";
import sound32 from "../../assets/battyboy.mp3";
import sound33 from "../../assets/stanley.mp3";
import sound34 from "../../assets/fake-news-great.mp3";
import sound35 from "../../assets/can-i-pet-that-dawg.mp3";
import sound36 from "../../assets/carole-baskin.mp3";
import sound37 from "../../assets/why-are-you-running.mp3";
import sound38 from "../../assets/three-am.mp3";
import sound39 from "../../assets/texas.mp3";
import sound40 from "../../assets/mayo-instrument.mp3";
import sound41 from "../../assets/barely.mp3";
import sound42 from "../../assets/nani.mp3";
import sound43 from "../../assets/yessir-this-guy-spittin.mp3";

// Probably should use something better than `sound#`

export const soundList = [
	{
		title: "Frolic",
		key: 1,
		src: sound1
	},
	{
		title: "Price is Right (Horn)",
		key: 2,
		src: sound2
	},
	{
		title: "Sad Trombone",
		key: 3,
		src: sound3
	},
	{
		title: "Oh Brother",
		key: 4,
		src: sound4
	},
	{
		title: "Circus",
		key: 5,
		src: sound5
	},
	{
		title: "What? (Peter Griffin)",
		key: 6,
		src: sound6
	},
	{
		title: "Bad Titanic",
		key: 7,
		src: sound7
	},
	{
		title: "Oh-No-No",
		key: 8,
		src: sound8
	},
	{
		title: "Ah Haha",
		key: 9,
		src: sound9
	},
	{
		title: "Anime Wow",
		key: 10,
		src: sound10
	},
	{
		title: "At This Moment",
		key: 11,
		src: sound11
	},
	{
		title: "Badum Ts",
		key: 12,
		src: sound12
	},
	{
		title: "Coffin Dance",
		key: 13,
		src: sound13
	},
	{
		title: "Coronavirus!",
		key: 14,
		src: sound14
	},
	{
		title: "It's Corona Time",
		key: 15,
		src: sound15
	},
	{
		title: "Come Here Boy",
		key: 16,
		src: sound16
	},
	{
		title: "Fortnite Down",
		key: 17,
		src: sound17
	},
	{
		title: "Ha Fish!",
		key: 18,
		src: sound18
	},
	{
		title: "I Didn't Ask",
		key: 19,
		src: sound19
	},
	{
		title: "Mexican Laugh",
		key: 20,
		src: sound20
	},
	{
		title: "Okay",
		key: 21,
		src: sound21
	},
	{
		title: "OMG Wow",
		key: 22,
		src: sound22
	},
	{
		title: "Sad Face",
		key: 23,
		src: sound23
	},
	{
		title: "Spongebob Fail",
		key: 24,
		src: sound24
	},
	{
		title: "Terrible Intro",
		key: 25,
		src: sound25
	},
	{
		title: "ISIS",
		key: 26,
		src: sound26
	},
	{
		title: "We Got Him!",
		key: 27,
		src: sound27
	},
	{
		title: "Worlds Smallest Violin",
		key: 28,
		src: sound28
	},
	{
		title: "Lamar",
		key: 29,
		src: sound29
	},
	{
		title: "I Hate It Here",
		key: 30,
		src: sound30
	},
	{
		title: "It's Patrick",
		key: 31,
		src: sound31
	},
	{
		title: "Battyboy!",
		key: 32,
		src: sound32
	},
	{
		title: "Stanley",
		key: 33,
		src: sound33
	},
	{
		title: "Fake News",
		key: 34,
		src: sound34
	},
	{
		title: "Can I Pet That Dawg?",
		key: 35,
		src: sound35
	},
	{
		title: "Carole Baskin",
		key: 36,
		src: sound36
	},
	{
		title: "Why You Running?",
		key: 37,
		src: sound37
	},
	{
		title: "Oh Boy 3AM",
		key: 38,
		src: sound38
	},
	{
		title: "I'm Texas",
		key: 39,
		src: sound39
	},
	{
		title: "Is Mayo?",
		key: 40,
		src: sound40
	},
	{
		title: "Barely",
		key: 41,
		src: sound41
	},
	{
		title: "NANI!",
		key: 42,
		src: sound42
	},
	{
		title: "This Guy Spittin!",
		key: 43,
		src: sound43
	}
];

const Sounds = () => {
	return (
		<div id="audio-files">
			{soundList.map((sound) => (
				<audio key={sound.key} data-key={sound.key} src={sound.src} />
			))}
		</div>
	);
};

export default React.memo(Sounds);

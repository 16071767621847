import React from "react";
import PropTypes from "prop-types";
import ButtonContainer from "./Styles";

const Button = ({ children, className = "", onClick = null }) => {
    return (
        <ButtonContainer className={className} onClick={onClick}>
            {children}
        </ButtonContainer>
    );
};

Button.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default React.memo(Button);

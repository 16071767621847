import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";

const StyleProvider = ({ children, theme }) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

StyleProvider.propTypes = {
    children: PropTypes.element.isRequired,
    theme: PropTypes.object.isRequired // eslint-disable-line
};

export default StyleProvider;

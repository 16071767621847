import styled from "styled-components";

const AppContainer = styled.div`
    .header,
    .sub-header {
        text-align: center;
    }

    .header {
        margin-bottom: 0px;
        font-weight: 400;
    }

    .sub-header {
        margin-top: 10px;
        margin-bottom: 30px;
        font-weight: 400;
    }

    .section-header {
        max-width: 1150px;
        font-weight: 500;
        margin: 20px 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid #cccccc;
    }

    .random-sound {
        width: 100%;

        &__card {
            margin: 30px auto 20px auto;
        }
    }

    .sound-list {
        &__cards {
            max-width: 1440px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: auto;
        }
    }

    @media (min-width: 500px) {
        .section-header {
            margin: 20px 15px;
        }
    }

    @media (min-width: 1150px) {
        .section-header {
            margin: 20px auto;
        }
    }
`;

AppContainer.displayName = "AppContainer";

export default AppContainer;

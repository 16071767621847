import React, { useState, useEffect } from "react";
import StyleProvider from "./theme";
import GlobalStyle from "./GlobalStyles";
import Sounds, { soundList } from "./components/Sounds";
import Card from "./components/Card";
import AppContainer from "./Styles";
import { lightTheme, darkTheme } from "./theme/themes";
import { getRandomNumber } from "./utils";

const App = () => {
	const themePreference = window.matchMedia("(prefers-color-scheme: dark)");
	const [activeSoundKey, setActiveSoundKey] = useState(0);
	const [allAudio, setAllAudio] = useState(null);
	const [isDarkMode, setIsDarkMode] = useState(themePreference.matches);
	const activeSoundTitle = soundList.find(
		(sound) => sound.key === activeSoundKey
	)?.title;
	const activeAudio = allAudio?.item(activeSoundKey - 1);

	useEffect(() => {
		setAllAudio(document.querySelectorAll("#audio-files audio"));
	}, []); // eslint-disable-line

	useEffect(() => {
		const darkModeToggler = (e) => {
			if (e.matches) {
				setIsDarkMode(true);
			} else {
				setIsDarkMode(false);
			}
		};

		themePreference.addListener(darkModeToggler);

		return () => {
			themePreference.removeListener(darkModeToggler);
		};
	}, []); // eslint-disable-line

	const resetActiveSoundKey = () => {
		setActiveSoundKey(0);
	};

	const soundClickHandler = (key) => {
		let audio = activeAudio;

		if (key !== activeSoundKey) {
			audio = allAudio.item(key - 1);
			setActiveSoundKey(key);
			allAudio.forEach((val) => {
				if (val.dataset.key !== key) {
					val.pause();
				}
			});
		}

		if (audio) {
			// Reset the audio by resetting src. Setting currentTime to 0
			// was causing issues with when introducing service workers.
			audio.src = audio.src; // eslint-disable-line no-self-assign
			if (audio.paused) audio.play();
		}
	};

	const randomClickHandler = () => {
		soundClickHandler(getRandomNumber(soundList.length));
	};

	return (
		<StyleProvider theme={isDarkMode ? darkTheme : lightTheme}>
			<>
				<GlobalStyle />
				<AppContainer>
					<h1 className="header">TheLitBoard</h1>
					<h4 className="sub-header">
						Click any button to hear a sound
					</h4>
					<section className="random-sound">
						<h4 className="section-header">Random Sound</h4>
						<Card
							// In safari sometimes the previous title will stay painted on the screen.
							// Adding a dynamic key seemed to fix it.
							key={new Date()}
							className="random-sound__card"
							title={activeSoundKey <= 0 ? "?" : activeSoundTitle}
							buttonText="Random"
							onClick={randomClickHandler}
							resetActiveSoundKey={resetActiveSoundKey}
							activeSoundKey={activeSoundKey}
							soundKey={
								activeSoundKey === 0 ? -1 : activeSoundKey
							}
						/>
					</section>
					<section className="sound-list">
						<h4 className="section-header">All Sounds</h4>
						<div className="sound-list__cards">
							{soundList.map((sound) => (
								<Card
									key={sound.key}
									title={sound.title}
									onClick={() => soundClickHandler(sound.key)}
									resetActiveSoundKey={resetActiveSoundKey}
									activeSoundKey={activeSoundKey}
									soundKey={sound.key}
								/>
							))}
						</div>
					</section>
					<Sounds />
				</AppContainer>
			</>
		</StyleProvider>
	);
};

export default App;
